export const ActionTypes = {
    SET_STORE_MODAL_POPUP:"SET_STORE_MODAL_POPUP",
    SET_DEVICE_ID:"SET_DEVICE_ID",
    SET_STORE_DEVICE_ID:"SET_STORE_DEVICE_ID",
    SET_STORE_CURRENT_USER:"SET_STORE_CURRENT_USER",
    SET_STORE_CATEGORY_LIST:"SET_STORE_CATEGORY_LIST",
    SET_STORE_MAGIC_CODE:"SET_STORE_MAGIC_CODE",
    SET_WEBSOCKET_REQ_RES:"SET_WEBSOCKET_REQ_RES",
    SET_STORE_MODAL_CONFIRM:"SET_STORE_MODAL_CONFIRM",
    SET_STORE_MODAL_CONFIRM_TWO:"SET_STORE_MODAL_CONFIRM_TWO",
    SET_STORE_COURSES_LIST:"SET_STORE_COURSES_LIST",
    SET_STORE_TESTIMONIAL_LIST:"SET_STORE_TESTIMONIAL_LIST",
    SET_STORE_UPDATE_TESTIMONIAL_LIST:"SET_STORE_UPDATE_TESTIMONIAL_LIST",
    SET_STORE_REVIEW_LIST:"SET_STORE_REVIEW_LIST",
    SET_STORE_REVIEW_UPDATE_LIST:"SET_STORE_REVIEW_UPDATE_LIST",
    SET_STORE_COURSES_LEVEL_LIST:"SET_STORE_COURSES_LEVEL_LIST",
    SET_STORE_COURSE_DETAILS:"SET_STORE_COURSE_DETAILS",
    SET_STORE_COURSE_UPDATE_LIST:"SET_STORE_COURSE_UPDATE_LIST",
    SET_STORE_PACKAGE_LIST:"SET_STORE_PACKAGE_LIST",
    SET_STORE_USER_MANAGER_LIST:"SET_STORE_USER_MANAGER_LIST",
    SET_STORE_SALES_HISTORY_LIST:"SET_STORE_SALES_HISTORY_LIST",
    SET_STORE_BRANCH_LIST:"SET_STORE_BRANCH_LIST",
    SET_STORE_USERS_DETAILS:"SET_STORE_USERS_DETAILS",
    SET_STORE_LOGIN_FORM:"SET_STORE_LOGIN_FORM",
    SET_STORE_FAQ_LIST:"SET_STORE_FAQ_LIST",
    SET_STORE_BLOG_LIST:"SET_STORE_BLOG_LIST",
    SET_STORE_PRIVACY_POLICY_LIST:"SET_STORE_PRICY_POLICY_LIST",
    SET_STORE_TERMS_AND_CONDITIONS_LIST:"SET_STORE_TERMS_AND_CONDITIONS_LIST",
    SET_STORE_DASHBOARD_COUNT:"SET_STORE_DASHBOARD_COUNT",
    SET_STORE_SELECT_WEBSITE:"SET_STORE_SELECT_WEBSITE",
    SET_STORE_ENROLLMENT_LIST:"SET_STORE_ENROLLMENT_LIST",
    SET_STORE_CONTACTS_LIST:"SET_STORE_CONTACTS_LIST",
    SET_STORE_ENROLLMENT_USER_DETAILS:"SET_STORE_ENROLLMENT_USER_DETAILS",
    SET_STORE_USER_COURSE_LIST:"SET_STORE_USER_COURSE_LIST",

}
export const setStoreSelectWebsite = (token) => {
    return {
       type: ActionTypes.SET_STORE_SELECT_WEBSITE,
       payload: token,
    }
};
export const setStoreCurrentUser = (token) => {
    return {
       type: ActionTypes.SET_STORE_CURRENT_USER,
       payload: token,
    }
};
export const setStoreCategoryList = (token) => {
    return {
       type: ActionTypes.SET_STORE_CATEGORY_LIST,
       payload: token,
    }
};
export const setDeviceId = (state) =>{
    return{
        type: ActionTypes?.SET_DEVICE_ID,
        payload: state
    }
}
export const setStoreMagicCode = (token) =>{
    return {
        type:ActionTypes.SET_STORE_MAGIC_CODE,
        payload:token,
    }
}
export const setWebsocketReqRes = (token) =>{
    return {
        type: ActionTypes.SET_WEBSOCKET_REQ_RES,
        payload: token,
    }
}
export const setConfirmModalPopup = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM,
            payload: {
                ...token,
                show: "CONFIRM_MODAL"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM,
        }
    }
}
export const setConfirmModalTwoPopup = (token) => {
    if(token){
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM_TWO,
            payload: {
                ...token,
                show: "CONFIRM_MODAL_TWO"
            },
         }
    }else{
        return {
            type: ActionTypes.SET_STORE_MODAL_CONFIRM_TWO,
        }
    }
}
export const setShowModalPopup = (token) => {
    return {
       type: ActionTypes.SET_STORE_MODAL_POPUP,
       payload: token,
    }
};
export const setStoreCoursesList = (token) => {
    return {
       type: ActionTypes.SET_STORE_COURSES_LIST,
       payload: token,
    }
};
export const setStoreTestimonialList = (token) => {
    return {
       type: ActionTypes.SET_STORE_TESTIMONIAL_LIST,
       payload: token,
    }
};
export const setStoreUpdateTestimonialList = (token) => {
    return {
       type: ActionTypes.SET_STORE_UPDATE_TESTIMONIAL_LIST,
       payload: token,
    }
};
export const setStoreReviewList = (token) => {
    return {
       type: ActionTypes.SET_STORE_REVIEW_LIST,
       payload: token,
    }
};
export const setStoreUpdateReviewList = (token) => {
    return {
       type: ActionTypes.SET_STORE_REVIEW_UPDATE_LIST,
       payload: token,
    }
};
export const setStoreCoursesLevelList = (token) => {
    return {
       type: ActionTypes.SET_STORE_COURSES_LEVEL_LIST,
       payload: token,
    }
};
export const setStoreCourseDetails = (token) => {
    return {
       type: ActionTypes.SET_STORE_COURSE_DETAILS,
       payload: token,
    }
};
export const setStoreCoursesUpdateList = (token) => {
    return {
       type: ActionTypes.SET_STORE_COURSE_UPDATE_LIST,
       payload: token,
    }
};
export const setStorePackageList = (token) => {
    return {
       type: ActionTypes.SET_STORE_PACKAGE_LIST,
       payload: token,
    }
};
export const setStoreUserManagerList = (token) => {
    return {
       type: ActionTypes.SET_STORE_USER_MANAGER_LIST,
       payload: token,
    }
};
export const setStoreDashboardCount = (token) => {
    return {
       type: ActionTypes.SET_STORE_DASHBOARD_COUNT,
       payload: token,
    }
};
export const setStoreSalesHistoryList = (token) => {
    return {
       type: ActionTypes.SET_STORE_SALES_HISTORY_LIST,
       payload: token,
    }
};
export const setStoreUserCourseList = (token) => {
    return {
       type: ActionTypes.SET_STORE_USER_COURSE_LIST,
       payload: token,
    }
};
export const setStoreBranchList = (token) => {
    return {
       type: ActionTypes.SET_STORE_BRANCH_LIST,
       payload: token,
    }
};
export const setStoreUsersDetails = (token) => {
    return {
       type: ActionTypes.SET_STORE_USERS_DETAILS,
       payload: token,
    }
};
export const setStoreLoginForm = (token) => {
    return {
       type: ActionTypes.SET_STORE_LOGIN_FORM,
       payload: token,
    }
};
export const setStoreFaqList = (token) => {
    return {
       type: ActionTypes.SET_STORE_FAQ_LIST,
       payload: token,
    }
};
export const setStoreBlogList = (token) => {
    return {
       type: ActionTypes.SET_STORE_BLOG_LIST,
       payload: token,
    }
};
export const setStorePrivacyPolicyList = (token) => {
    return {
       type: ActionTypes.SET_STORE_PRIVACY_POLICY_LIST,
       payload: token,
    }
};
export const setStoreTermsAndConditionsList = (token) => {
    return {
       type: ActionTypes.SET_STORE_TERMS_AND_CONDITIONS_LIST,
       payload: token,
    }
};
export const setStoreEnrollmentList = (token) => {
    return {
       type: ActionTypes.SET_STORE_ENROLLMENT_LIST,
       payload: token,
    }
};
export const setStoreContactsList = (token) => {
    return {
       type: ActionTypes.SET_STORE_CONTACTS_LIST,
       payload: token,
    }
};
export const setStoreEnrollmentUserDetails = (token) => {
    return {
       type: ActionTypes.SET_STORE_ENROLLMENT_USER_DETAILS,
       payload: token,
    }
};